//====================================================
//  Function: Gallery
//====================================================
var gallery
function componentGallery() {
  if ($('.js-gallery').length) {
    gallery = $('.js-gallery')
    gallery.lightGallery({
      selector: '.js-gallery-item',
      hash: false,
      zoom: false,
      autoplay: false,
      autoplayFirstVideo: false,
      thumbnail: false,
      rotate: false,
      share: false,
      share: false,
      fullScreen: false,
      download: false,
    })
  }
}
